*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
    height: 100vh;
}

