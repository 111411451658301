  .footerWrapper {
    text-align: center;
    color: #fff;
    background-color: #262E3F;
  }
 
  .footer a {
    color: #fff;
  }

  .logo {
    max-width: 160px;;
  }